<template>
  <div class="animated fadeIn">
    <TableWrapper
      :ref="model.name"
      :parent-id="orderId"
      actions-mode="inline"
      :model="model"
      :columns="columns"
      :read-only="readOnly"
      :editable-columns="editableColumns"
      :cell-classes="cellClasses"
      :custom-actions="customActions"
      @inline-dropdown-change="onItemsLookupClick"
      @updated="onItemsTableUpdate"
      @inserted="onItemsTableInsert"
      @deleted="onItemsTableDelete"
      @loaded="onItemsTableLoad"
    ></TableWrapper>
  </div>
</template>

<script>
import TableWrapper from '@/components/DataView/TableWrapper'
import models from '@/models'

export default {
  name: 'IncomingOrderItemsTable',
  props: {
    orderId: {
      type: [String, Number],
      default: ''
    },

    readOnly: {
      type: Boolean,
      default: false
    }
  },
  components: { TableWrapper },
  data: function () {
    return {
      data: [],
      model: models.warehouse.incomingOrderItems,
      columns: models.warehouse.incomingOrderItems.entities.map(e => e.name),
      editableColumns: models.warehouse.incomingOrderItems.entities
        .filter(e => e.readonly == false)
        .map(e => e.name),

      cellClasses: {
        'Bottles Diff': [
          {
            class: 'bg-danger',
            condition: row => +row['Bottles Diff'] < 0
          },
          {
            class: 'bg-success',
            condition: row => +row['Bottles Diff'] > 0
          }
        ],
        'Cases Diff': [
          {
            class: 'bg-danger',
            condition: row => +row['Cases Diff'] < 0
          },
          {
            class: 'bg-success',
            condition: row => +row['Cases Diff'] > 0
          }
        ]
      },
      customActions: [
        /*
        {
          click: this.onItemsLookupClick,
          title: "Warehouse",
          icon: "search"
        }
        */
      ],
      dictionaries: {
        products: []
      }
    }
  },
  computed: {},
  created () {
    this.initialize()
  },
  async mounted () {
    this.dictionaries.products = await this.$api.get(
      `/dictionaries/finished-products`
    )
  },
  methods: {
    async initialize () {},
    getData () {
      return this.$refs[this.model.name].getData()
    },
    getDataSet () {
      return this.$refs[this.model.name].getDataSet()
    },

    onItemsTableInsert (e) {
      console.log('onItemsTableInsert.e', e.uid)
      let data = this.$refs[this.model.name].getDataSet()
      console.log('onItemsTableInsert.data', data)

      let insertedProductRecords = data.filter(
        i =>
          i['Finished Product_ID'] == e['Finished Product_ID'] &&
          i['Bottle Size_ID'] == e['Bottle Size_ID'] &&
          i['Cost per Case'] == e['Cost per Case'] &&
          i['Organic Status_ID'] == e['Organic Status_ID']
      )

      if (insertedProductRecords.length == 2) {
        //remove inserted
        data = data.filter(i => i.ID !== e.uid)
        //add
        insertedProductRecords[0]
      }

      this.updateComputedColumns()

      this.$emit('changed', this.getDataSet())
    },
    onItemsTableDelete () {
      this.$emit('changed', this.getDataSet())
    },
    onItemsTableUpdate () {
      this.updateComputedColumns()

      this.$emit('changed', this.getDataSet())
    },
    async updateComputedColumns () {
      console.log('IncomingOrderItemsTable.updateComputedColumns')
      let data = this.$refs[this.model.name].getDataSet()

      for (let row of data) {
        let productId = row['Finished Product_ID']
        // console.log('IncomingOrderItemsTable.updateComputedColumns.row:', row)
        let product = this.dictionaries.products.find(p => p.id == productId)

        // console.log(
        //   'IncomingOrderItemsTable.updateComputedColumns.product:',
        //   productId,
        //   product
        // )

        let bottlesPerCase = 6

        if (product.product_group) {
          bottlesPerCase = product.case_size_bottles
        }

        row['Line Cost'] = this.$helpers.round(
          (row['Cost per Case'] * row['Bottles']) / bottlesPerCase,
          4
        )

        // row['Bottle Size_ID'] = product.bottle_size_id
        // row['Bottle Size'] = '200'

        row['Cases'] = this.$helpers.round(row['Bottles'] / bottlesPerCase, 2)
      }

      this.$refs[this.model.name].updateDataSet(data)
    },
    onItemsLookupClick (e) {
      console.log('IncomingOrderItemsTable.onItemsLookupClick', e)

      //this.updateComputedColumns()

      //this.$emit('changed', this.getDataSet())
    },
    onItemsTableLoad (e) {
      this.$emit('loaded', e)
      // this.updateComputedColumns()
      let items = this.$refs[this.model.name].getDataSet()

      // console.log('IncomingOrderItemsTable.onItemsTableLoad', e, items)

      this.$emit('loaded', items)
    }
  }
}
</script>

<style scoped>
::v-deep .table-responsive {
  overflow: visible !important;
}
::v-deep table {
  overflow: visible !important;
}
</style>
